import type { Locale } from "../../core/schema/Locale.js";
import type { TenantId } from "../../core/schema/Tenant.js";

export const globalSettingsContextKey = "globalSettings";

export interface GlobalSettings {
	AWS_IDENTITY_POOL_ID: string;
	AWS_REGION: string;
	AWS_USER_POOL_CLIENT_ID: string;
	AWS_USER_POOL_ID: string;
	COOKIES: string;
	DOMAIN: string;
	ENV: string;
	ENV_DOMAIN: string;
	FF_B2B: "true" | "false";
	FF_SHOWN_PROMO: "xmas" | "valentines" | "easter" | "none";
	FF_SHOW_VALENTINES_IN_MENU: "true" | "false";
	FF_SHOW_XMAS_IN_MENU: "true" | "false";
	FF_SHOW_EASTER_IN_MENU: "true" | "false";
	GOOGLE_ADS: string;
	GOOGLE_ANALYTICS: string;
	META_PIXEL: string;
	PUBLIC_GRAPHQL_ENDPOINT: string;
	ROBOTS: "index" | "noindex";
	SENTRY_DSN: string;
	SMARTFORM: string;
	SMARTLOOK_PROJECT_KEY: string;
	SMARTLOOK_REGION: string;
	STORAGE_URL: string;
	TENANT_ID: TenantId;
	TENANT_LOCALE: Locale;
	UPLOAD_BUCKET: string;
	UPLOAD_URL: string;
	VERSION: string;
	WEB_URL: string;
}
